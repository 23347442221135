class AIPrompt {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async getPrompts() {
    const path = `${this.baseUrl}/prompts`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);

    return body;
  }

  async getRecentsPrompts() {
    const path = `${this.baseUrl}/prompts/recents`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async postPrompt({ infos }) {
    const path = `${this.baseUrl}/prompts/layer-1`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        infos,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postPrompt2({ id }) {
    const path = `${this.baseUrl}/prompts/layer-2`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async postPrompt3({ id }) {
    const path = `${this.baseUrl}/prompts/layer-3`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    });

    const body = await response.json();

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error('Error to get response. Contact Shifter\'s team');
      } else {
        throw new Error(`${response.status} - ${body.message}`);
      }
    }

    return body;
  }

  async updateName({ id, newName }) {
    const path = `${this.baseUrl}/prompts/edit-name`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'PUT',
      body: JSON.stringify({ id, newName }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async deleteOne({ projectID }) {
    const path = `${this.baseUrl}/prompts/${projectID}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findOne({ id }) {
    const path = `${this.baseUrl}/prompts/${id}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new AIPrompt();

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ManageProjectContext } from '../manageProjects.context';

import IconEdit from '../../../../../styles/logos/icon_edit_pencil.svg';

import { SidebarArea } from './style';

export function Sidebar() {
  const { listOfPrompts, isLoadingPromptList } = useContext(ManageProjectContext);

  return (
    <SidebarArea>
      {isLoadingPromptList && (
        <>Loading List...</>
      )}
      {!isLoadingPromptList && (
        <>
          <div className="title-area">
            <p>
              <Link to="/client/copycut/manage-projects" alt="Manager prompt">
                Gerenciador de Prompts
              </Link>
            </p>
          </div>
          <div>
            <ul>
              {listOfPrompts.length > 0 && (
                listOfPrompts.map((prompt) => (
                  <li key={prompt.id}>
                    <Link
                      className="prompt-name"
                      to={`project-info/${prompt.id}`}
                    >
                      {prompt.name}
                    </Link>
                    <span>
                      <Link to={`edit-name/${prompt.id}?name=${prompt.name}`}>
                        <img src={IconEdit} alt="edit" />
                      </Link>
                    </span>
                  </li>
                ))
              )}
            </ul>
          </div>
        </>
      )}
    </SidebarArea>
  );
}

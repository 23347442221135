export function checkOpenTabs() {
  return new Promise((resolve) => {
    const channel = new BroadcastChannel('app_tabs');
    let hasResponse = false;

    // Send message to verify tabs
    channel.postMessage({ type: 'TAB_CHECK' });

    // Define a timout to awit responses
    const timeout = setTimeout(() => {
      channel.close();
      resolve(false);
    }, 1000);

    // Listem others tabs
    channel.onmessage = (event) => {
      if (event.data.type === 'TAB_RESPONSE') {
        hasResponse = true;
        clearTimeout(timeout);
        channel.close();
        resolve(true);
      }
    };
  });
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  margin: 0;
  position: relative;
`;

export const SideBarArea = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 25%;
  margin-left: -20px;
  margin-top: -20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 30%;
  }
`;

export const RoutesArea = styled.div`
  width: 75%;
  padding-left: 20px;
  min-height: 100vh;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 70%;
  }
`;




export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  const terms = {
    doneBy: 'Created by: ',

    labelBusinessModel: 'Business Model',
    labelNiche: 'Niche',
    labelSite: 'Site / Landing Page data / Competitors',
    labelLanguage: 'Language',
    labelToneOfVoice: 'Tone of Voice',

    modalTitle: 'Are you sure you want to delete this item?',
    modalBtn: 'Delete',

    titleVslSMBPolicyFree: 'VSL SMB (Policy Free)',
    titleVslEnterprisePolicyFree: 'VSL Enterprise (Policy Free)',
    titleSingleMechanism: 'Single Mechanism',

    h4TitleVslCopy: 'VSL Copy (B2C/SMB and Enterprise)',
    h4TitleVslPolicyFree: 'VSL Policy Free (B2C/SMB and Enterprise)',

    toneOfVoiceSaleMode: 'Sale Mode',
    toneOfVoiceProfissional: 'Professional',
    toneOfVoiceExpert: 'Expert',
    toneOfVoiceBusinessAndTrust: 'Business and Trust',
    toneOfVoiceSensitiveCommunication: 'Sensitive Communication',
    toneOfVoiceFriendly: 'Friendly',

    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        doneBy: 'Criado por: ',

        labelBusinessModel: 'Modelo de Negócio',
        labelNiche: 'Nicho',
        labelSite: 'Dados do site / Landing Page / Concorrentes',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tom de voz',

        modalTitle: 'Tem certeza que deseja deletar esse item?',
        modalBtn: 'Deletar',

        titleVslSMBPolicyFree: 'VSL SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'VSL Enterprise (Livre de Políticas)',
        titleSingleMechanism: 'Mecanismo Único',

        h4TitleVslCopy: 'VSL Copy (B2C/SMB e Enterprise)',
        h4TitleVslPolicyFree: 'VSL Livre de Políticas (B2C/SMB e Enterprise)',

        toneOfVoiceSaleMode: 'Modo venda',
        toneOfVoiceProfissional: 'Profissional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negócios e Confiança',
        toneOfVoiceSensitiveCommunication: 'Comunicação Sensível',
        toneOfVoiceFriendly: 'Amigável',

        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        doneBy: 'Criado por: ',

        labelBusinessModel: 'Modelo de Negócio',
        labelNiche: 'Nicho',
        labelSite: 'Dados do site / Landing Page / Concorrentes',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tom de voz',

        modalTitle: 'Tem a certeza que pretende eliminar este item?',
        modalBtn: 'Eliminar',

        titleVslSMBPolicyFree: 'VSL SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'VSL Enterprise (Livre de Políticas)',
        titleSingleMechanism: 'Mecanismo Único',

        h4TitleVslCopy: 'VSL Copy (B2C/SMB e Enterprise)',
        h4TitleVslPolicyFree: 'VSL Livre de Políticas (B2C/SMB e Enterprise)',

        toneOfVoiceSaleMode: 'Modo venda',
        toneOfVoiceProfissional: 'Profissional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negócios e Confiança',
        toneOfVoiceSensitiveCommunication: 'Comunicação Sensível',
        toneOfVoiceFriendly: 'Amigável',

        language,
      };

    case 'es-ES':
      return {
        ...terms,
        doneBy: 'Creado por: ',

        labelBusinessModel: 'Modelo de Negocio',
        labelNiche: 'Nicho',
        labelSite: 'Datos del sitio / Landing Page / Competidores',
        labelLanguage: 'Idioma',
        labelToneOfVoice: 'Tono de voz',

        modalTitle: '¿Estás seguro de que quieres eliminar este elemento?',
        modalBtn: 'Eliminar',

        titleVslSMBPolicyFree: 'VSL SMB (Libre de Políticas)',
        titleVslEnterprisePolicyFree: 'VSL Enterprise (Libre de Políticas)',
        titleSingleMechanism: 'Mecanismo Único',

        h4TitleVslCopy: 'VSL Copy (B2C/SMB y Enterprise)',
        h4TitleVslPolicyFree: 'VSL Libre de Políticas (B2C/SMB y Enterprise)',

        toneOfVoiceSaleMode: 'Modo de venta',
        toneOfVoiceProfissional: 'Profesional',
        toneOfVoiceExpert: 'Especialista',
        toneOfVoiceBusinessAndTrust: 'Negocios y Confianza',
        toneOfVoiceSensitiveCommunication: 'Comunicación Sensible',
        toneOfVoiceFriendly: 'Amigable',

        language,
      };

    default:
      return terms;
  }
}

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ManageProjectContext } from '../../manageProjects.context';

import Loader from '../../../../../../components/Loader';
import { NoDataMessage } from '../../../../../../components/NoDataMessage';

import Dates_New from '../../../../../../helpers/Dates_New';

import IconBooks from '../../../../../../styles/logos/icon_article.svg';
import IconText from '../../../../../../styles/logos/icon_text.svg';

import { Translate } from './translate';
import { ContainerMessage } from './style';

export function Content() {
  const translate = Translate();
  const { listOfRecentsPrompts, isLoadingRecentsPromptList } = useContext(ManageProjectContext);

  return (
    <ContainerMessage>

      <Link className="link-container" to="/client/copycut">
        <div>
          <h2>{translate.newProject}</h2>
          <span>{translate.newProjectMsg}</span>
        </div>
        <img src={IconBooks} alt="Books" />
      </Link>

      <div className="recents-container">
        <h3>{translate.recents}</h3>

        {isLoadingRecentsPromptList && <Loader loading={isLoadingRecentsPromptList} />}

        {(!isLoadingRecentsPromptList && listOfRecentsPrompts.length === 0) && (
        <NoDataMessage>{translate.noDataMsg}</NoDataMessage>
        )}

        {(!isLoadingRecentsPromptList && listOfRecentsPrompts.length > 0) && (
          <div className="recents-area">
            {listOfRecentsPrompts.map((prompt) => (
              <Link
                key={prompt.id}
                className="recent-card"
                to={`project-info/${prompt.id}`}
              >
                <img src={IconText} alt="book" />
                <span className="name">{prompt.name}</span>
                <span className="hour">{Dates_New.formatDatesMaskWithHour(prompt.dateAct)}</span>
              </Link>
            ))}
          </div>
        )}
      </div>
    </ContainerMessage>
  );
}


export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  // Pattern => en-US
  const terms = {
    title: 'New Project',
    messageProccessInfo: 'We are organizing your information. This may take up to 90 seconds. Please do not leave this screen.',

    btnOne: 'Generate VSL Copy (B2C/SMB e Enterprise)',
    btnTwo: 'Generate VSL Policy Free (B2C/SMB e Enterprise)',
    btnThree: 'Generate Assets Copy - Marketing and Sales',

    h4TitleVslCopy: 'VSL Copy (B2C/SMB and Enterprise)',
    h4TitleVslPolicyFree: 'VSL Policy Free (B2C/SMB and Enterprise)',
    h4TitleAssets: 'Assets Copy - Marketing and Sales',

    questionModel: 'Is your business B2C or B2B?',
    questionLanguage: 'What\'s the language of the answers?',
    questionToneOfVoice: 'Tone of Voice',
    questionAvatar: 'What is your perfect customer? (ICP / Audience / Persona / AVATAR)',
    questionNiche: 'What is your niche market? (Niche or mass market)',
    questionSite: 'Site Data / Landing Page Data / Competitors Data / Emails Data?',

    placeholderAvatar: 'Enter your avatar\'s information',
    placeholderNiche: 'Enter your niche\'s information',
    placeholderSite: 'Enter your site\'s information',

    titleVslSMBPolicyFree: 'VSL SMB (Policy Free)',
    titleVslEnterprisePolicyFree: 'VSL Enterprise (Policy Free)',
    titleSalesConversationalB2C: 'Sales Conversation B2C/ SMB',
    titleSalesConversationalEnterprise: 'Sales Conversation Enterprise',
    titleSingleMechanism: 'Unique Mechanism',

    optionSellingMode: 'Sale Mode',
    optionFriendly: 'Friendly',
    optionProfessional: 'Professional',
    optionExpert: 'Expert',
    optionBusinessAndTrust: 'Business and Trust',
    optionSocialMedia: 'Social Media',
    optionSensitiveCommunication: 'Sensitive Communication',

    btnAddAll: 'Add all',
    btnDeleteAll: 'Delete all',
    btnExplore: 'Explore templates',
    language,
  };

  switch (language) {
    case 'pt-BR':
      return {
        ...terms,
        title: 'Novo Projeto',
        messageProccessInfo: 'Estamos organizando suas informações. Isso pode levar até 90 segundos. Por favor, não saia desta tela.',

        btnOne: 'Gerar VSL Copy (B2C/SMB e Enterprise)',
        btnTwo: 'Gerar VSL Livre de Políticas (B2C/SMB e Enterprise)',
        btnThree: 'Gerar Assets Copy - Marketing e Vendas',

        h4TitleVslCopy: 'VSL Copy (B2C/SMB e Enterprise)',
        h4TitleVslPolicyFree: 'VSL Livre de Política (B2C/SMB e Enterprise)',
        h4TitleAssets: 'Assets Copy - Marketing e Vendas',

        questionModel: 'Seu negócio é de ofertas B2C ou B2B?',
        questionLanguage: 'Qual o idioma das respostas?',
        questionToneOfVoice: 'Tom de Voz',
        questionAvatar: 'Qual é o seu cliente perfeito? (ICP / Audiência / Persona / AVATAR)',
        questionNiche: 'Qual é o seu nicho de mercado? (Nicho ou mercado de massas)',
        questionSite: 'Dados do site / Landing Page / Concorrentes/ Dados de e-mail?',

        placeholderAvatar: 'Insira as informações do seu avatar',
        placeholderNiche: 'Insira as informações do seu nicho',
        placeholderSite: 'Insira as informações do seu site',

        titleVslSMBPolicyFree: 'VSL SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'VSL Enterprise (Livre de Políticas)',
        titleSalesConversationalB2C: 'Conversa de Vendas B2C/SMB',
        titleSalesConversationalEnterprise: 'Conversa de Vendas Enterprise',
        titleSingleMechanism: 'Mecanismo Único',

        optionSellingMode: 'Modo venda',
        optionFriendly: 'Amigável',
        optionProfessional: 'Profissional',
        optionExpert: 'Expecialista',
        optionBusinessAndTrust: 'Negócios e Confiança',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicação Sensível',

        btnAddAll: 'Adicionar todos',
        btnDeleteAll: 'Deletar todos',
        btnExplore: 'Explorar templates',
        language,
      };

    case 'pt-PT':
      return {
        ...terms,
        title: 'Novo Projeto',
        messageProccessInfo: 'Estamos a organizar as suas informações. Isto pode demorar até 90 segundos. Por favor, não saia deste ecrã.',

        btnOne: 'Gerar VSL Copy (B2C/SMB e Enterprise)',
        btnTwo: 'Gerar VSL Livre de Políticas (B2C/SMB e Enterprise)',
        btnThree: 'Gerar Assets Copy - Marketing e Vendas',

        h4TitleVslCopy: 'VSL Copy (B2C/SMB e Enterprise)',
        h4TitleVslPolicyFree: 'VSL Livre de Política (B2C/SMB e Enterprise)',
        h4TitleAssets: 'Assets Copy - Marketing e Vendas',

        questionModel: 'O seu negócio é B2C ou B2B?',
        questionLanguage: 'Qual é o idioma das respostas?',
        questionToneOfVoice: 'Tom de Voz',
        questionAvatar: 'Qual é o seu cliente perfeito? (ICP / Audiência / Persona / AVATAR)',
        questionNiche: 'Qual é o seu nicho de mercado? (Nicho ou mercado de massas)',
        questionSite: 'Dados do site / Landing Page / Concorrentes / Dados de e-mail?',

        placeholderAvatar: 'Insira as informações do seu avatar',
        placeholderNiche: 'Insira as informações do seu nicho',
        placeholderSite: 'Insira as informações do seu site',

        titleVslSMBPolicyFree: 'VSL SMB (Livre de Políticas)',
        titleVslEnterprisePolicyFree: 'VSL Enterprise (Livre de Políticas)',
        titleSalesConversationalB2C: 'Conversa de Vendas B2C/SMB',
        titleSalesConversationalEnterprise: 'Conversa de Vendas Enterprise',
        titleSingleMechanism: 'Mecanismo Único',

        optionSellingMode: 'Modo de venda',
        optionFriendly: 'Amigável',
        optionProfessional: 'Profissional',
        optionExpert: 'Expecialista',
        optionBusinessAndTrust: 'Negócios e Confiança',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicação Sensível',

        btnAddAll: 'Adicionar todos',
        btnDeleteAll: 'Apagar todos',
        btnExplore: 'Explorar templates',
        language,
      };

    case 'es-ES':
      return {
        ...terms,
        title: 'Nuevo Proyecto',
        messageProccessInfo: 'Estamos organizando tu información. Esto puede tardar hasta 90 segundos. Por favor, no abandones esta pantalla.',

        btnOne: 'Generar VSL Copy (B2C/SMB y Enterprise)',
        btnTwo: 'Generar VSL Libre de Políticas (B2C/SMB y Enterprise)',
        btnThree: 'Generar Assets Copy - Marketing y Ventas',

        h4TitleVslCopy: 'VSL Copy (B2C/SMB y Enterprise)',
        h4TitleVslPolicyFree: 'VSL Libre de Política (B2C/SMB y Enterprise)',
        h4TitleAssets: 'Assets Copy - Marketing y Ventas',

        questionModel: '¿Tu negocio es B2C o B2B?',
        questionLanguage: '¿Cuál es el idioma de las respuestas?',
        questionToneOfVoice: 'Tono de Voz',
        questionAvatar: '¿Cuál es tu cliente perfecto? (ICP / Audiencia / Persona / AVATAR)',
        questionNiche: '¿Cuál es tu nicho de mercado? (Nicho o mercado masivo)',
        questionSite: '¿Datos del sitio web / Landing Page / Competidores / Datos de correo electrónico?',

        placeholderAvatar: 'Ingresa la información de tu avatar',
        placeholderNiche: 'Ingresa la información de tu nicho',
        placeholderSite: 'Ingresa la información de tu sitio web',

        titleVslSMBPolicyFree: 'VSL SMB (Libre de Políticas)',
        titleVslEnterprisePolicyFree: 'VSL Enterprise (Libre de Políticas)',
        titleSalesConversationalB2C: 'Conversación de Ventas B2C/SMB',
        titleSalesConversationalEnterprise: 'Conversación de Ventas Enterprise',
        titleSingleMechanism: 'Mecanismo Único',

        optionSellingMode: 'Modo venta',
        optionFriendly: 'Amigable',
        optionProfessional: 'Profesional',
        optionExpert: 'Expecialista',
        optionBusinessAndTrust: 'Negocios y Confianza',
        optionSocialMedia: 'Social Media',
        optionSensitiveCommunication: 'Comunicación Sensible',

        btnAddAll: 'Agregar todos',
        btnDeleteAll: 'Eliminar todos',
        btnExplore: 'Explorar plantillas',
        language,
      };

    default:
      return terms;
  }
}

import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { faBrazilianRealSign } from '@fortawesome/free-solid-svg-icons';
import { ManageProjectContext } from '../../manageProjects.context';
import { AuthContext } from '../../../../../../context/AuthContext';

import Loader from '../../../../../../components/Loader';
import { Button } from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';

import IconClipboard from '../../../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../../../styles/logos/check_icon.svg';
import IconTrash from '../../../../../../styles/logos/icon_trash_white.svg';
import IconEdit from '../../../../../../styles/logos/icon_edit_pencil.svg';
import IconSound from '../../../../../../styles/logos/sound.svg';

import { Translate } from './translate';
import {
  Container, TitleContainer, EntranceContainer, ResponseContainer, ResponseCardsContainer, Card,
} from './style';

export function ProjectInfos() {
  const translate = Translate();
  const { id } = useParams();
  const {
    setProjectID, projectInfos, isLoadingProjectInfo, toogleModal, handleToogleModal, handleDeleteProject,
  } = useContext(ManageProjectContext);
  const { userInfo } = useContext(AuthContext);

  const [visibleCards, setVisibleCards] = useState({
    foundationalCopy: false,
    foundationalCopyHumanized: false,
  });

  // CHANGE ID FROM Context function
  useEffect(() => {
    setProjectID(id);
  }, [id]);

  function handleCardsFoundational(containerId, event) {
    if (event.target.tagName === 'IMG') return;
    setVisibleCards((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  function translateToneOfVoice(toneOfVoice) {
    let toneOfVoiceTranslated = '';

    switch (toneOfVoice) {
      case 'saleMode':
        toneOfVoiceTranslated = translate.toneOfVoiceSaleMode;
        break;

      case 'profissional':
        toneOfVoiceTranslated = translate.toneOfVoiceProfissional;
        break;

      case 'expert':
        toneOfVoiceTranslated = translate.toneOfVoiceExpert;
        break;

      case 'businessAndTrust':
        toneOfVoiceTranslated = translate.toneOfVoiceBusinessAndTrust;
        break;

      case 'sensitiveCommunication':
        toneOfVoiceTranslated = translate.toneOfVoiceSensitiveCommunication;
        break;

      case 'friendly':
        toneOfVoiceTranslated = translate.toneOfVoiceFriendly;
        break;

      default:
        toneOfVoiceTranslated = toneOfVoice.charAt(0).toUpperCase() + toneOfVoice.slice(1);
        break;
    }

    return toneOfVoiceTranslated;
  }

  return (
    <>
      {isLoadingProjectInfo && <Loader loading={isLoadingProjectInfo} />}
      {!isLoadingProjectInfo && (
        <Container>
          <TitleContainer>
            <div className="title-infos">
              <div className="title-area">
                <h2>{projectInfos?.name}</h2>
                <Link to={`/client/copycut/manage-projects/edit-name/${projectInfos.id}?name=${projectInfos.name}`}>
                  <img src={IconEdit} alt="edit" />
                </Link>
              </div>
              <p>{translate.doneBy}<span>{projectInfos?.firstName} {projectInfos?.lastName} | {projectInfos?.email}</span></p>
            </div>
            <div className="title-actions">
              <Button
                color="red"
                onClick={() => handleToogleModal()}
              >
                <img
                  src={IconTrash}
                  alt="Delete Prompt"
                />
              </Button>
            </div>
          </TitleContainer>

          <EntranceContainer>
            <h3>Inputs</h3>
            <div className="info-group-d-flex">
              <div className="info-group">
                <label htmlFor="avatar">{translate.labelBusinessModel}</label>
                <div>{projectInfos?.businessModel ? projectInfos?.businessModel : '---'}</div>
              </div>
              <div className="info-group">
                <label htmlFor="avatar">{translate.labelLanguage}</label>
                <div>{projectInfos?.language ? projectInfos?.language : '---'}</div>
              </div>
              <div className="info-group">
                <label htmlFor="avatar">{translate.labelToneOfVoice}</label>
                <div>{projectInfos?.toneOfVoice ? translateToneOfVoice(projectInfos?.toneOfVoice) : '---'}</div>
              </div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">Avatar</label>
              <div>{projectInfos?.avatar}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelNiche}</label>
              <div>{projectInfos?.niche}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">{translate.labelSite}</label>
              <div>{projectInfos?.siteData}</div>
            </div>
          </EntranceContainer>

          <ResponseContainer>
            <h3>Outputs</h3>
            <div className="container-painels">

              <div className="container-layers">
                <h4>{translate.h4TitleVslCopy}</h4>
                <ResponseCardsContainer>
                  {/* TODO: Melhorar isso aqui depois */}
                  {userInfo.email === 'suporte+111menu@shifters.com.br' && (
                  <>
                    {(projectInfos?.foundationalCopy && projectInfos?.foundationalCopy.length > 0) && (
                    <Card $visible={visibleCards?.foundationalCopy}>
                      <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopy', e)}>
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {projectInfos?.foundationalCopy}
                      </div>
                    </Card>
                    )}
                    {(projectInfos?.foundationalCopyHumanized && projectInfos?.foundationalCopyHumanized.length > 0) && (
                    <Card $visible={visibleCards?.foundationalCopyHumanized}>
                      <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopyHumanized', e)}>
                        <span>...</span>
                        <img
                          src={IconClipboard}
                          alt="Copy"
                          onClick={(e) => copyContent(e)}
                        />
                      </div>
                      <div className="infos">
                        {projectInfos?.foundationalCopyHumanized}
                      </div>
                    </Card>
                    )}
                  </>
                  )}

                  {(projectInfos?.vslSmb && projectInfos?.vslSmb.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>VSL SMB</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslSmb}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.vslEnterprise && projectInfos?.vslEnterprise.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>VSL Enterprise</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslEnterprise}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>

              <div className="container-layers">
                <h4>{translate.h4TitleVslPolicyFree}</h4>
                <ResponseCardsContainer>
                  {(projectInfos?.vslSmbPolicyFree && projectInfos?.vslSmbPolicyFree.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleVslSMBPolicyFree}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslSmbPolicyFree}
                    </div>
                  </Card>
                  )}

                  {(projectInfos?.vslEnterprisePolicyFree && projectInfos?.vslEnterprisePolicyFree.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleVslEnterprisePolicyFree}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.vslEnterprisePolicyFree}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>


              <div className="container-layers">
                <h4>ASSETS Copy - Marketing e Vendas</h4>
                <ResponseCardsContainer>
                  {(projectInfos?.headlines && projectInfos?.headlines.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Headlines</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.headlines}
                    </div>
                  </Card>
                  )}
                  {(projectInfos?.singleMechanism && projectInfos?.singleMechanism.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>{translate.titleSingleMechanism}</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.singleMechanism}
                    </div>
                  </Card>
                  )}
                  {(projectInfos?.salesLetterSmb && projectInfos?.salesLetterSmb.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Comercial SMB</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.salesLetterSmb}
                    </div>
                  </Card>
                  )}
                  {(projectInfos?.salesLetterEnterprise && projectInfos?.salesLetterEnterprise.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Comercial Enterprise</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.salesLetterEnterprise}
                    </div>
                  </Card>
                  )}
                  {(projectInfos?.caseStudyB2B && projectInfos?.caseStudyB2B.length > 0) && (
                  <Card $visible>
                    <div className="title">
                      <span>Case Study B2B</span>
                      <img
                        src={IconClipboard}
                        alt="Copy"
                        onClick={(e) => copyContent(e)}
                      />
                    </div>
                    <div className="infos">
                      {projectInfos?.caseStudyB2B}
                    </div>
                  </Card>
                  )}
                </ResponseCardsContainer>
              </div>
            </div>


          </ResponseContainer>
        </Container>
      )}
      <Modal
        danger
        visible={toogleModal}
        title={translate.modalTitle}
        btnMessage={translate.modalBtn}
        onAction={() => handleDeleteProject()}
        onClose={() => handleToogleModal()}
      >
        {projectInfos?.name}
      </Modal>
    </>

  );
}

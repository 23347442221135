import { PropTypes } from 'prop-types';
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { checkOpenTabs } from '../../helpers/checkOpenTabs';
import toast from '../../helpers/toast';

import LoginService from '../../services/Login';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [authenticated, setAuthenticated] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [accountNames, setAccountNames] = useState([]);
  const [accessHash, setAccessHash] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialPath, setInitialPath] = useState(location.pathname);

  useEffect(() => {
    setInitialPath(location.pathname); // Set initial path on mount
    verifyAuthentication();
  }, []);

  useEffect(() => {
    const channel = new BroadcastChannel('app_tabs');

    // Este listener vai ficar ativo em todas as abas
    channel.onmessage = (event) => {
      if (event.data.type === 'TAB_CHECK' && authenticated) {
        // Se esta aba estiver autenticada, responde que está ativa
        channel.postMessage({ type: 'TAB_RESPONSE' });
      }
    };

    // Cleanup quando o componente for desmontado
    return () => channel.close();
  }, [authenticated]);

  async function verifyAuthentication() {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const response = await LoginService.verifyToken(token);
        const { decode } = await response.json();
        setAuthenticated(true);
        insertInfosOnLocalStorageAndVarLocals(decode);
        navigate(initialPath, { replace: true });
      } catch (err) {
        setAuthenticated(false);
        navigate('/');
      }
    } else {
      setAuthenticated(false);
      removeLocalStorageItems();
      navigate('/');
    }
    setLoading(false);
  }

  async function handleLogin(form) {
    try {
      const hasOpenTabs = await checkOpenTabs();
      const dataUser = JSON.parse(localStorage.getItem('clientInfo'));

      // If has token at localStorage
      if (dataUser) {
        if (hasOpenTabs && (dataUser.email !== form.email)) {
          toast('danger', 'Active session detected. Close other tabs before login.');
          return;
        }
      }

      const data = await LoginService.post(form);
      insertInfosOnLocalStorageAndVarLocals(data);
      setAuthenticated(true);
      setLoading(false);

      if (data.perfil === 'SHIFTER') {
        const routeToGo = initialPath === '/' || initialPath.includes('/client')
          ? '/admin'
          : initialPath;

        navigate(routeToGo, { replace: true });
      } else if (data.perfil === 'B2B' || data.perfil === 'B2C') {
        const routeToGo = initialPath === '/' || initialPath.includes('/admin')
          ? '/client'
          : initialPath;

        navigate(routeToGo, { replace: true });
      }
    } catch (err) {
      console.log(err);
      const error = JSON.parse(err.message);
      restartStates();

      if (error.code === 403) {
        navigate('/trial');
      } else {
        toast('danger', `${error.code} - ${error.message}`);
        navigate('/');
        window.location.reload();
      }
    }
  }

  function handleLogout() {
    restartStates();
    setInitialPath('/'); // Reset initial path on logout
    navigate('/');
  }

  function removeLocalStorageItems() {
    localStorage.removeItem('token');
    localStorage.removeItem('clientInfo');
  }

  function restartStates() {
    setAuthenticated(false);
    setUserInfo({});
    setAccountNames([]);
    removeLocalStorageItems();
  }

  function insertInfosOnLocalStorageAndVarLocals(data) {
    localStorage.setItem('language', JSON.stringify(data.userInfo.language));
    localStorage.setItem('token', JSON.stringify(data.token));
    if (data.clientInfo) localStorage.setItem('clientInfo', JSON.stringify({ ...data.clientInfo, email: data.userInfo.email }));
    setUserInfo({
      firstName: data.userInfo.firstName,
      lastName: data.userInfo.lastName,
      email: data.userInfo.email,
      admin: data.admin,
      perfil: data.perfil,
      typeClient: data.clientInfo?.type_client,
    });
    if (data.accountNames) setAccountNames(data.accountNames);
    setAccessHash(data.accessHash);
  }

  const contextValues = useMemo(() => ({
    authenticated,
    userInfo,
    accountNames,
    accessHash,
    loading,
    handleLogin,
    handleLogout,
  }), [authenticated, userInfo, accountNames, accessHash, loading, handleLogin, handleLogout]);

  return (
    <AuthContext.Provider value={contextValues}>
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };


import styled from 'styled-components';

export const ContainerMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;
  width: 100%;

  .link-container {
    padding: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    gap: 24px;
    border: 1px solid ${({ theme }) => theme.gray2};
    border-radius: 4px;
    margin-bottom: 36px;
    width: 100%;
    transition: .2s ease-in-out;

    &:hover {
      border-color: ${({ theme }) => theme.fontWhite};
      background-color: ${({ theme }) => theme.grayMenu};
    }

    div {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 40px;
    }
  }

  .recents-container {
    h3 {
      margin-bottom: 16px;
      font-weight: 500;
    }

    .recents-area {
      display: grid;
      grid-template-columns: repeat(3, 220px);
      justify-content: center;
      width: 100%;
      gap: 16px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: column;
      }
    }

    .recent-card {
      flex-grow: 1;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;
      gap: 8px;
      border: 1px solid ${({ theme }) => theme.gray2};
      border-radius: 4px;
      width: 100%;
      transition: .2s ease-in-out;

      &:hover {
        border: 1px solid ${({ theme }) => theme.fontWhite};
        background-color: ${({ theme }) => theme.grayMenu};
      }

      img {
        width: 16px;
      }

      .name {
        word-wrap: break-word;
      }

      .hour {
        font-size: 12px;
        color: ${({ theme }) => theme.gray4}
      }
    }
  }
`;

